import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import HomeOverview from "./views/HomeOverview.vue";
import Vergaderen from "./views/Vergaderen.vue";
import Congres from "./views/Congres.vue";
import Presenteren from "./views/Presenteren.vue";
import Eten from "./views/Eten.vue";
import Activiteiten from "./views/Activiteiten.vue";
import UwEvent from "./views/UwEvent.vue";
import OnzeRuimtes from "./views/OnzeRuimtes.vue";

import Vergaderzalen from "./views/Vergaderzalen.vue";
import VergaderzalenOverview from "./views/VergaderzalenOverview.vue";
import Hall1 from "./views/Hall-1.vue";
import Hall2 from "./views/Hall-2.vue";
import Hall3 from "./views/Hall-3.vue";
import Hall4 from "./views/Hall-4.vue";
import Hall5 from "./views/Hall-5.vue";
import Hall6 from "./views/Hall-6.vue";
import Hall4_1 from "./views/Hall-4_1.vue";
import Hall4_2 from "./views/Hall-4_2.vue";
import PrivateDining from "./views/PrivateDining.vue";

import Forum from "./views/Forum.vue";
import Theater from "./views/Theater.vue";
import Contact from "./views/Contact.vue";

import Static from "./views/Static.vue";

import Error404 from "./views/404.vue";

Vue.use(Router);

export default new Router({
  mode: "history",

  routes: [
    {
      path: "/",
      name: "",
      component: Home,

      children: [
        {
          path: "/",
          name: "HomeOverview",
          component: HomeOverview
        },
        {
          path: "onze-ruimtes",
          name: "OnzeRuimtes",
          component: OnzeRuimtes
        },
        {
          path: "uw-event",
          name: "UwEvent",
          component: UwEvent
        },
        {
          path: "contact",
          name: "Contact",
          component: Contact
        },
        {
          path: "static",
          name: "Static",
          component: Static
        },
        {
          path: "vergaderen",
          name: "Vergaderen",
          component: Vergaderen
        },
        {
          path: "congres-&-beurs",
          name: "CongresBeurs",
          component: Congres
        },
        {
          path: "presenteren",
          name: "Presenteren",
          component: Presenteren
        },
        {
          path: "eten-&-drinken",
          name: "EtenDrinken",
          component: Eten
        },
        {
          path: "activiteiten",
          name: "Activiteiten",
          component: Activiteiten
        },
        {
          path: "vergaderzalen",
          // since Vue always render the (default) child route on / we can remove the name from the parent
          name: "",
          component: Vergaderzalen,

          children: [
            {
              path: "/",
              name: "VergaderzalenOverview",
              component: VergaderzalenOverview
            },
            {
              path: "4-1-vergaderzaal",
              name: "Hall-4_1",
              component: Hall4_1
            },
            {
              path: "4-2-vergaderzaal",
              name: "Hall-4_2",
              component: Hall4_2
            },
            {
              path: "6-1-vergaderzaal",
              name: "Hall-1",
              component: Hall1
            },
            {
              path: "6-2-vergaderzaal",
              name: "Hall-2",
              component: Hall2
            },
            {
              path: "6-3-singelzaal",
              name: "Hall-3",
              component: Hall3
            },
            {
              path: "6-4-herenzaal",
              name: "Hall-4",
              component: Hall4
            },
            {
              path: "6-5-keizerzaal",
              name: "Hall-5",
              component: Hall5
            },
            {
              path: "6-6-prinsenzaal",
              name: "Hall-6",
              component: Hall6
            }
          ]
        },
        {
          path: "forum",
          name: "Forum",
          component: Forum
        },
        {
          path: "theater",
          name: "Theater",
          component: Theater
        },
        {
          path: "private-dining",
          name: "PrivateDining",
          component: PrivateDining
        },
        {
          path: "/*",
          name: "Error404",
          component: Error404
        }
      ]
    }
  ]
});
