<template>
  <transition name="fade">
    <div>
      <Hero alt="vergaderzalen" heading="vergaderzalen" />
      <Gallery
        :type="6"
        :slides="slidesGallery"
        info="Wij presenteren u acht stijlvolle vergaderzalen op de vierde en de zesde verdieping. U heeft de beschikking over comfortabele designmeubilair en in bijvoorbeeld de Prinsenzaal wordt u getrakteerd op een prachtig uitzicht over de stad.<br/><br/>
        Alle ruimtes zijn flexibel inzetbaar voor gezelschappen tot 90 personen en standaard voorzien van een beamer, flipover, whiteboard, internetaansluiting en verder zijn bijna al onze ruimtes voorzien van een audio video conference set waarmee de deelnemers op afstand kunnen inbellen.<br/><br/>
        Hiernaast beschikken wij over andere ruimtes, verspreid door het gebouw, die zich goed lenen voor gezelschappen tot 20 personen. Deze ruimtes zijn uitermate geschikt voor kleinere workshops, vergaderingen en break-out sessies. Heeft u interesse in een kleinere ruimte? Vraag ernaar bij OBA Congres."
        brochure="true"
      />
      <Slider :type="6" heading="zalen" :slides="slides" />
      <Table heading="overzicht" :spaces="spaces.filter(space => space.size === 'medium')" />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Gallery from "@/components/Gallery.vue";
import Slider from "@/components/Slider.vue";
import Table from "@/components/Table.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import { spacesInfo, meetingSpacesSlides } from "@/data/spaces.js";

import AOS from "aos";

export default {
  name: "Vergaderzalen",

  components: {
    Hero,
    Gallery,
    Slider,
    Table,
    Form,
    Footer
  },

  data() {
    return {
      slidesGallery: [
        { delay: 0, bg: "Vergaderzalen-1" },
        { delay: 100, bg: "Vergaderzalen-2" },
        { delay: 200, bg: "Vergaderzalen-3" },
        { delay: 300, bg: "Vergaderzalen-4" },
        { delay: 400, bg: "Vergaderzalen-5" },
        { delay: 500, bg: "Vergaderzalen-6" }
      ],

      slides: meetingSpacesSlides,

      spaces: spacesInfo
    };
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
